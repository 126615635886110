import React from 'react';
import {Row, Col} from 'react-bootstrap';

export default class Contact extends React.Component {
  state = {
    status: 'ready',
    name: '',
    email: '',
    message: ''
  }

  submit = (e) => {
    e.preventDefault();
    const data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      source: this.props.source
    };
    if (this.validate(data)) {
      fetch('https://api.pourmgmt.com/commerce/contact', {
        body: JSON.stringify(data),
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: { 'content-type': 'application/json' },
        method: 'POST', mode: 'cors', redirect: 'follow', referrer: 'no-referrer',
      }).then(res => {
        this.setState({
          status: 'sent',
          email: '',
          name: '',
          message: ''
        })
      }).catch((err) => {this.setState({status: 'error'})});
    }
  }

  validate = (data) => {
    if (!(data.name && data.name.length > 2)) return false;
    if (!(data.email && data.email.length > 3 && data.email.includes('@'))) return false;
    if (!(data.message && data.message.length > 5)) return false;
    return true
  }

  render() {
    const messages = {
      ready: {message: this.props.message, color: null},
      sent: {message: 'Your message is on the way!', color: 'green'},
      error: {message: 'Sorry there was an error :-(', color: 'red'}
    };
    return (
    <div className="contact-form-inner">
         <div className="contact-form-titile text-center">
           {messages[this.state.status].message ? <h3 style={{color: messages[this.state.status].color}}>{messages[this.state.status].message}</h3> : null}
         </div>
        <div className="row">
             <form id="contact-form">
                 <p className="form-messege"></p>
                  <Col sm={12} sm={6} md={6}>
                    <div className="input-type">
                        <input value={this.state.name} onChange={e => this.setState({name: e.target.value})} type="text" placeholder="Your Name" style={{fontSize: '16px'}} aria-label="Input name"/>
                    </div>
                 </Col>
                 <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="input-type">
                      <input value={this.state.email} onChange={e => this.setState({email: e.target.value})} type="email" placeholder="E-mail" style={{fontSize: '16px'}} aria-label="Input email"/>
                    </div>
                 </div>
                 <div className="col-md-12 col-sm-12 col-xs-12">
                     <div className="message-box mt-50">
                        <textarea value={this.state.message} onChange={e => this.setState({message: e.target.value})} placeholder="Message" style={{fontSize: '16px', minHeight: '150px'}} aria-label="Input message"></textarea>
                     </div>
                     <div className="send-bt text-center">
                        {this.state.status != 'sending' ? <button onClick={this.submit}>Send message</button> : null}
                     </div>
                 </div>
            </form>
        </div>
    </div>
  )
  }
}
