import React from 'react';
import Contact from 'pourmgmt/contact';
import {Row, Col} from 'react-bootstrap';
import Layout from '../layouts';

export default (props) => (
  <Layout {...props} nav="contact">
  <Row>
    <Col md={8} mdOffset={2}>
      <Contact
        message="Shoot us a text at 707-492-5510 or send us an email"
        source="www.iconicwine.com"
      />
    </Col>
  </Row>
  </Layout>
)
